var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('div',{staticClass:"filters row",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"col-1-1"},[_c('div',{staticClass:"pull-right"},[_c('CButton',{staticClass:"m-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.openCreate()}}},[_c('fa-icon',{attrs:{"icon":"plus"}}),_vm._v(" Criar ")],1)],1)])])]),_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"table-filter":"","items-per-page-select":"","items-per-page":10,"hover":"","sorter":"","pagination":{ doubleArrows: true, align: 'center'},"border":"","tableFilter":{label: 'Filtrar por:', placeholder: '...'},"noItemsView":{noItems: 'Nada encontrado'}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"actions"},[_c('button',{staticClass:"button button-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.openEdit(item)}}},[_c('fa-icon',{attrs:{"icon":"pencil-alt"}})],1),_c('button',{staticClass:"button button-error",attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.deleteConfirm(item.id)}}},[_c('fa-icon',{attrs:{"icon":"trash-alt"}})],1)])]}},{key:"percentual",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"label success"},[_c('strong',[_vm._v(" "+_vm._s(item.percentual)+"% ")])])])]}},{key:"produto_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.produto_id != null ? item.produto_id : '-')+" ")])]}},{key:"descricao",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.descricao != null ? item.descricao : '-')+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }