<template>
  <div>
    <CRow>
      <div class="filters row" style="margin-top:0">
        <div class="col-1-1">
          <div class="pull-right">
            <CButton color="success" class="m-2" @click="openCreate()">
              <fa-icon icon="plus" />
                Criar
            </CButton>        
          </div>
        </div>
      </div>
    </CRow>
    <CDataTable
      :items="items"
      :fields="fields"
      table-filter
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      :pagination="{ doubleArrows: true, align: 'center'}"
      border
      :tableFilter="{label: 'Filtrar por:', placeholder: '...'}"
      :noItemsView="{noItems: 'Nada encontrado'}"
    >
      <template #actions="{item}">
        <td class="actions">
          <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>                                            
          <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
        </td>
      </template>
      <template #percentual="{item}">
        <td>
          <span class="label success"><strong> {{ item.percentual }}% </strong></span>
        </td>
      </template>
      <template #produto_id="{item}">
        <td>
           {{ item.produto_id != null ? item.produto_id : '-' }} 
        </td>
      </template>
      <template #descricao="{item}">
        <td>
           {{ item.descricao != null ? item.descricao : '-' }} 
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
const fields = [  
  {
    key: 'descricao',
    label: 'Descrição',
    sorter: true, 
    filter: false 
  },  
  {
    key: 'produto_id',
    label: 'Produto',
    sorter: true, 
    filter: false 
  },
  {
    key: 'percentual',
    label: 'Percentual',
    sorter: true, 
    filter: false 
  },
  {
    key: 'actions',
    label: 'Ações',
    _style: { width: '5%'},
    sorter: true, 
    filter: false 
  },
]

  export default {
    name: 'list',
    props: [ 'items' , 'openEdit', 'openCreate', 'deleteConfirm'],
    data() {
      return {
        fields,
      }
    },
    computed: {},
    mounted() {},
    methods: {}
  }
</script>