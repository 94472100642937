<template>
  <div>
    <PrecificacaoClienteFinalEdit
      :item="currentItem"
      :clientes="clientes"
      :produtos="produtos"
      :dimensoes="dimensoes"
      :update="updateItem"      
      :close="closeEdit"
      :descSingular="descSingular"
    ></PrecificacaoClienteFinalEdit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Regras Markup">
        <PrecificacaoClienteFinalTable :items="items" :openEdit="openEdit" :openCreate="openCreate" :deleteConfirm="deleteConfirm"/>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import PrecificacaoClienteFinalEdit from './components/PrecificacaoClienteFinalEdit';
import PrecificacaoClienteFinalTable from './components/PrecificacaoClienteFinalTable';
import _filter      from 'lodash/filter';

const Item = {
  id          : null,
  descricao   : null,
  cliente_id  : null,
  dimensao1   : null,
  dimensao2   : null,
  dimensao3   : null,
  dimensao4   : null,
  dimensao5   : null,
  dimensao6   : null,
  dimensao7   : null,
  dimensao8   : null,
  dimensao9   : null,
  dimensao10  : null,
  produto_id  : null,
  percentual  : 0,
}

export default {
  name: 'PrecificacaoClienteFinal',
  components: {
    PrecificacaoClienteFinalEdit,
    PrecificacaoClienteFinalTable,
  },
  data() {
    return {
      descPlural   : '',
      descSingular : '',
      loading      : false,
      items        : [],
      modulos      : [],
      currentItem  : {},
      clientes     : [],
      produtos     : [],
      dimensoes    : [],
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_PRECIFICACAO_CLIENTE_FINAL'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadPrecificacoes() {
      this.loading = true;
      get('/admin/precificacao/all')
        .then((json) => {
          this.items       = json;
          this.loading     = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate() {
      this.currentItem = clone(Item);
      this.$modal.show('PrecificacaoClienteFinalEdit');
    },
    closeCreate() {
      this.$modal.hide('PrecificacaoClienteFinalEdit');
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.loading     = false;
      this.$modal.show('PrecificacaoClienteFinalEdit');
    },
    closeEdit() {
      this.$modal.hide('PrecificacaoClienteFinalEdit');
    },
    doRequest() {
      return this.currentItem.id ?
      put(`/admin/precificacao/${this.currentItem.id}`, this.currentItem)
      :
      post('/admin/precificacao', this.currentItem);
    },
    updateItem() {
      this.doRequest()
        .then((json) => {
          this.closeEdit();
          this.loadPrecificacoes();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/precificacao/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: 'Removido com sucesso',
                  icon: 'success',
                });
                this.loadPrecificacoes();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    loadClientes() {
      this.loading = true;
      get('/admin/precificacao/clientes')
        .then((json) => {
          this.clientes = json;
          this.loading  = false;
        })
        .catch(() => (this.loading = false));
    },
    loadProdutos() {
      this.loading = true;
      get('/admin/lista-produtos')
        .then((json) => {
          this.produtos = json;
          this.loading  = false;
        })
        .catch(() => (this.loading = false));
    },
    loadDimensoes() {
      this.loading = true;
      get('/admin/lista-dimensoes')
        .then((json) => {
          this.dimensoes = json;
          this.loading   = false;
        })
        .catch(() => (this.loading = false));
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadPrecificacoes();
    this.loadDescricaoModulo();
    //this.loadClientes();
    this.loadProdutos();
    this.loadDimensoes();
  },
};
</script>
