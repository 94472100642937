<template>
  <div>
    <modal name="PrecificacaoClienteFinalEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close" />
      <div class="modalbox">
        <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`${descSingular}`">
            <div class="xrow"> 
              <div class="col-1-3">
                <div class="form-group">
                  <label for="">Descrição</label>                
                  <input class="form-control" v-model="item.descricao" />
                  <div class="message">{{ validation.firstError('item.descricao') }}</div>
                </div>
              </div>
               
              <div class="col-2-3">
                <div class="form-group">
                  <label>Percentual</label>
                  <InputPercent
                    :value="item.percentual"
                    v-model="item.percentual"
                  />
                  <div class="message">{{ validation.firstError("item.percentual") }}</div>
                </div>
              </div>

              <div class="col-1-1" v-if="!tipoDefinido() " style="text-align: right;" > 
                <button class="button button-primary" v-on:click="limpaFiltros">Voltar </button>
              </div>
             

              <div class="col-1-1" style="margin-bottom: 25px;" v-if="item.dimensao1 == null && item.dimensao2 == null && item.dimensao3 == null && item.dimensao4 == null && item.dimensao5 == null && item.dimensao6 == null && item.dimensao7 == null  ">
                <div class="form-group">
                  <label>Produto</label>
                  <ModelListSelect :list="produtos"
                     v-model="item.produto_id"
                     option-value="id"
                     option-text="descComp"
                     :custom-text="textProduto"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>

              <div v-if="item.produto_id == null">
              <div class="col-1-1">
                <div class="form-group">
                  <label> <strong> Dimensões </strong> </label>
                  <hr>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(1)">
                  <label> {{ formatDimensoes(1).label }} </label>                  
                  <ModelListSelect :list="formatDimensoes(1).dimensoes"
                     v-model="item.dimensao1"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(2)">
                  <label> {{ formatDimensoes(2).label }} </label>
                  <ModelListSelect :list="formatDimensoes(2).dimensoes"
                     v-model="item.dimensao2"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(3)">
                  <label> {{ formatDimensoes(3).label }} </label>
                  <ModelListSelect :list="formatDimensoes(3).dimensoes"
                     v-model="item.dimensao3"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(4)">
                  <label> {{ formatDimensoes(4).label }} </label>
                  <ModelListSelect :list="formatDimensoes(4).dimensoes"
                     v-model="item.dimensao4"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(5)">
                  <label> {{ formatDimensoes(5).label }} </label>
                  <ModelListSelect :list="formatDimensoes(5).dimensoes"
                     v-model="item.dimensao5"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(6)">
                  <label> {{ formatDimensoes(6).label }} </label>
                  <ModelListSelect :list="formatDimensoes(6).dimensoes"
                     v-model="item.dimensao6"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(7)">
                  <label> {{ formatDimensoes(7).label }} </label>
                  <ModelListSelect :list="formatDimensoes(7).dimensoes"
                     v-model="item.dimensao7"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(8)">
                  <label> {{ formatDimensoes(8).label }} </label>
                  <ModelListSelect :list="formatDimensoes(8).dimensoes"
                     v-model="item.dimensao8"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(9)">
                  <label> {{ formatDimensoes(9).label }} </label>
                  <ModelListSelect :list="formatDimensoes(9).dimensoes"
                     v-model="item.dimensao9"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
              <div class="col-1-2">
                <div class="form-group" v-if="!!formatDimensoes(10)">
                  <label> {{ formatDimensoes(10).label }} </label>
                  <ModelListSelect :list="formatDimensoes(10).dimensoes"
                     v-model="item.dimensao10"
                     option-value="id"
                     option-text="desc"
                     :custom-text="textDimensao"
                     placeholder="Pesquisar">
                  </ModelListSelect>
                </div>
              </div>
            </div>
            </div>
            
            <div class="xrow edit-buttons">
              <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit">
                Salvar
              </button>
              &nbsp;
              <button class="button" v-on:click="close">Fechar</button>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    </modal>
  </div>
</template>

<script>
  import { Validator } from "simple-vue-validator";
  import CloseModal from "../../../components/CloseModal";
  import InputPercent from "../../../components/InputPercent";
  import { ModelListSelect } from 'vue-search-select'
  import 'vue-search-select/dist/VueSearchSelect.css'

  export default {
    name: 'PrecificacaoClienteFinalEdit',
    props: [
      'item', 
      'update', 
      'close' , 
      'modulos', 
      'descSingular', 
      'clientes', 
      'produtos', 
      'dimensoes',
    ],
    components: { CloseModal, InputPercent, ModelListSelect },
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    validators: {
      "item.descricao":  (value) => Validator.value(value).required(),
      "item.percentual": (value) => Validator.value(value).required(),
    },
    methods: {
      handleSubmit() {
        this.$validate().then((success) => {
          if (success) {
            this.update();
          }
        });
      },
      formatDimensoes(coluna) {
        return this.dimensoes.find((d) => d.coluna === coluna);
      },
      textCliente (item) {
        return `${item.id} - ${item.fant}`
      },
      textProduto (item) {
        return `${item.id} - ${item.descComp}`
      },
      textDimensao (item) {
        return `${item.id} - ${item.desc}`
      },
      tipoDefinido () {
        return !(this.item.produto_id != null) &&  !(this.item.dimensao1 != null || this.item.dimensao2 != null || this.item.dimensao3 != null || this.item.dimensao4 != null || this.item.dimensao5 != null || this.item.dimensao6 != null || this.item.dimensao7 != null) 
      },
      limpaFiltros() {
        this.item.produto_id = null;
        this.item.dimensao1 = null;
        this.item.dimensao2 = null;
        this.item.dimensao3 = null;
        this.item.dimensao4 = null;
        this.item.dimensao5 = null;
        this.item.dimensao6 = null;
        this.item.dimensao7 = null;
      }
    }
  }
</script>